.pacs-app-layout {
  overflow-x: auto;
  overflow-y: auto;

  &.side-nav-open {
    .side-navigation {
      left: 0;
      width: 250px;
      border-right: 1px solid #CCC;
    }

    .app-container {
      width: calc(100vw - 250px);
      left: 250px;
    }
  }

  .side-navigation {
    width: 0;
    left: -250px;
    max-height: 100vh;
    overflow-y: auto;
    background: #FFF;

    .regions-tree-view {
      .btn-clear {
        margin-left: 0px;
        width: 32px;
        height: 32px;
      }

      .react-autosuggest__container {
        width: 100%;
        // max-width: 150px;
        overflow-x: hidden;

        .react-autosuggest__input {
          height: 32px;
          font-size: 14px;
          border-right: 0;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }

      .react-autosuggest__suggestions-container {
        font-size: 14px;
      }
    }
  }

  .react-autosuggest__container {
    max-width: 100%;
  }

  .app-container {
    width: 100vw;
  }

  .app-content {
    height: calc(100vh - 84px);
    overflow-y: auto;
  }

  .table-analysis {
    thead tr th {
      font-size: 1.1rem;
    }

    tbody tr td {
      font-size: 1.0rem;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      color: #000;
    }

    tbody tr td.text-white {
      color: #FFF;
    }

    tbody tr td.text-editable {
      color: #00F;
      //font-weight: bold;
    }
  }

  .tab {
    padding-left: 10px;
    padding-right: 10px;
  }

  .big-tab-items {
    a {
      height: 50px;
      line-height: 20px;
      font-size: 13px;
    }
  }

  .focused-edit-form {
    input, select, textarea {
      font-size: 1.1rem;
    }
  }

  .check-disabled {
    cursor: alias;
  }

  .antenna-operator-selection {
    .react-autosuggest__container {
      width: 18em;
    }
  }

  .autocomplete-in-editor {
    .react-autosuggest__container {
      max-width: 100%;

      .react-autosuggest__input {
        border-color: #ddd;
      }
    }
  }

  .defects-list-card {
    .table tr:last-child td:first-child {
      border-bottom-left-radius: 0.4rem;
    }

    .table tr:last-child td:last-child {
      border-bottom-right-radius: 0.4rem;
    }
  }

  .comments-container {
    //min-height: 350px;
    max-height: none; // max(calc(100vh - 500px), 90vh);
    height: auto; // max(calc(100vh - 500px), 90vh);
    overflow-x: hidden;
    overflow-y: auto;
  }

  .divider::before, .divider::after {
    height: 0.0625rem;
  }

  .divider-primary::before, .divider-primary::after {
    background-color: #CCC;
  }
}
