.modal-sm {
  .modal-box {
    max-width: 40vw;
  }
}

.modal-lg {
    .modal-box {
        max-width: 70vw;
    }
}

.modal-xl {
  .modal-box {
    max-width: 90vw;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
