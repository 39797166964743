@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');
@import "react-datepicker/dist/react-datepicker.css";
@import "react-datetime/css/react-datetime.css";
@import "aos/dist/aos.css";
@import "react-image-gallery/styles/scss/image-gallery.scss";
@import "github-markdown-css/github-markdown.css";
@import "github-markdown-css/github-markdown-light.css";
//@import 'draft-js/dist/Draft.css';
@import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
@import 'react-circular-progressbar/dist/styles.css';

.site-audit-history {
  .react-datepicker__navigation--previous,
  .react-datepicker__navigation--next {
    top: 18px;
  }
}

.weather-details {
  .react-datepicker__navigation--previous,
  .react-datepicker__navigation--next {
    top: 9px;
  }
}

$brand-color: #0BA0DC;
$brand-color-hover: #097ead;
$custom-green-color: #cefad0;
$custom-red-color: #ffc9bb;
$minor-yellow-color: rgb(250, 204, 21);
$major-orange-color: rgb(251 146 60 );

body {
  font-family: 'Inter', sans-serif;
  min-height: 100vh;
}

.fade-in {
  animation: fadeIn 5s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.bg-dreamy {
  background-image: url("../../public/img/bg-texture1.png");
  background-size: cover;
}

.bg-content-dreamy {
  background-image: url("../../public/img/bg-texture-vlight.jpg");
  background-size: cover;
}

.input-disabled,
.input:disabled,
.input[disabled],
.select-disabled,
.select:disabled,
.select[disabled] {
  background-color: #FFF;
}

.rdtPickerOpenUpwards .rdtPicker {
  top: auto;
  bottom: 100%;
}

.tab.tab-active:not(.tab-disabled):not([disabled]) {
  border-style: solid;
  border-color: $brand-color;
  border-bottom-width: 2px;
}

.bg-brand {
  background-color: $brand-color;
}

.btn-brand {
  background-color: $brand-color;
  @apply rounded-md text-white px-4 py-2;

  &:hover {
    background-color: $brand-color-hover;
  }
}

.text-brand {
  color: $brand-color;
}

.text-editable {
  color: #00F;
  //font-weight: bold;
}

.border-brand {
  border-color: $brand-color;
}

.bg-custom-green {
  background-color: $custom-green-color;
}

.bg-custom-red {
  background-color: $custom-red-color;
}

.h-scroll-container {
  height: calc(100vh - 30vh);
  overflow-y: auto;
}

.bg-minor-defect {
  background: $minor-yellow-color;
}

.text-minor-defect {
  color: $minor-yellow-color;
}

.bg-major-defect {
  background: $major-orange-color;
}

.text-major-defect {
  color: $major-orange-color;
}

.bg-maintenance-defect {
  background: $brand-color;
}

.btn-close-popup {
  position: absolute;
  right: 5px;
  z-index: 55;
  top: 5px;
}

.regions-tree-view {
  .react-autosuggest__container--open .react-autosuggest__suggestions-container {
    position: relative;
    top: 0;
  }
}

.select-customer-container {
  .react-autosuggest__container {
    width: 300px;
    max-width: 300px;
  }
}

@import 'autocomplete';
@import 'header';
@import 'footer';
@import "login";
@import "ahu_view";
@import "admin";
@import "request_forms";
@import "toggle";
@import "reports";
@import "pacs_layout";

.post-editor .ql-editor {
  min-height: 10rem;
}

div.file-tree, div.file-tree > div.file-name {
  list-style: none;
  margin: 0;
  padding: 0;
}
div.file-tree > div {
  margin-left: 10px;
}
div.file-tree > div.file-name {
  margin: 0;
  padding: 0 7px;
  line-height: 20px;
  //color: #369;
  //font-weight: bold;
  border-left: 1px solid rgba(180,180,180, 40);
}
div.file-tree > div:last-child {
  border-left:none;
}
div.file-tree > div.file-name:before {
  position:relative;
  top:-0.3em;
  height:1em;
  width:12px;
  color:white;
  border-bottom: 1px solid rgba(180,180,180, 40);
  content:"";
  display:inline-block;
  left:-7px;
}
div.file-tree > div:last-child:before {
  border-left: 1px solid rgba(180,180,180, 40);
}
