$clr-primary: steelblue;

.react-autosuggest__container {
	width: 24em;
	height: auto;
	position: relative;
}

.react-autosuggest__input {
	display: block;
	width: 100%;
	height: 2.8em;
	// padding: 1em 1.5em;
	box-sizing: border-box;
	font-size: 1em;
	border: 1px solid #aaa;
	border-radius: 7px;
	outline: none;
	transition: border-radius 250ms ease;
	//border-bottom-right-radius: 0;
	//border-top-right-radius: 0;
	
	&:focus {
		outline: none;
		border: 1px solid $clr-primary;
	}
	.react-autosuggest__container--open & {
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
		border-bottom: 0;
	}
}

.react-autosuggest__suggestions-container {
	width: 100%;
	visibility: hidden;
	will-change: opacity;
	position: relative;
	overflow: hidden;
	transform-origin: 50% 0%;
	transform: scale(1, 0);
	opacity: 0;
	transition: all 250ms ease;
	z-index: 50;

	.react-autosuggest__container--open & {
		display: block;
		position: absolute;
		top: 2.8em;
		width: 100%;
		transform: scale(1, 1);
		visibility: visible;
		opacity: 1;
		box-sizing: border-box;
		border: 1px solid $clr-primary;
		border-top: 0;
		background-color: #fff;
		font-family: Helvetica, sans-serif;
		border-radius: 1em;
		border-top-left-radius: 0;
		border-top-right-radius: 0;
		z-index: 50;
		.suggestion {
			display: flex;
			justify-content: flex-start;
			align-items: center;
		}
	}
}

.react-autosuggest__suggestions-list {
	margin: 0;
	padding: 0;
	list-style-type: none;
}

.react-autosuggest__suggestion {
    cursor: pointer;
    box-sizing: border-box;
    padding: 0.5em 0.75em;
    
    &:hover {
        background: #DEDEDE;
    }
}

.react-autosuggest__suggestion--focused .suggestion{
	color: lighten($clr-primary, 10%);
	b {
		color: darken($clr-primary, 5%);
	}
}
