.reports-theme {
  * {
    color: #000;
  }

  .report-page {
    position: relative;
    margin: 0.6rem 0.6rem 0;
  }

  @media print {
    @page {
      size: A4 landscape;
      margin: 0cm 0cm 0cm 0cm;
    }

    body {
      zoom: 0.7;
    }

    .report-page {
      height: 98vh;
      page-break-before: avoid;
      -webkit-region-break-inside: avoid;

      width: 98vw;
      height: 98vh;
      clear: both;

      table tr {
        page-break-before:avoid;
        page-break-after:auto;
        position:relative;
        -webkit-region-break-inside: avoid;
      }
    }

    .report-footer {
      position: absolute;
      left: 0;
      bottom: 10px;
    }
  }
}

